import React from "react";
import * as contentComponents from "./content";

const typeMap = {
  paragraph: "Paragraph",
  text: "Paragraph",
  list: "List"
};

const RenderProgramContent = props => {
  const { content = [] } = props;
  return (
    <div>
      {content &&
        content.map((contentData, idx) => {
          const contentType = typeMap[contentData.slice_type];
          if (!contentType) return null;

          return contentComponents[contentType]({ ...contentData, key: idx });
        })}
    </div>
  );
};

export default RenderProgramContent;
