import React from "react";

const List = props => {
  return (
    <div key={props.key}>
      <p className="paragraph">{props.primary.listprefix.text || ""}</p>
      <ul className="List">
        {props.items &&
          props.items.map(({ listitem }, idx) => {
            return <li key={idx}>{listitem.text}</li>;
          })}
      </ul>
    </div>
  );
};

export default List;
