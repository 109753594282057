import React from "react";
import cx from "classnames";

import "./paragraph.css";

const Paragraph = props => {
  return (
    <p
      key={props.key}
      className={cx("paragraph", {
        "paragraph--italic": props.primary.style === "italic"
      })}
    >
      {props.primary.text.text}
    </p>
  );
};

export default Paragraph;
