import React from "react";
import { StaticQuery, graphql } from "gatsby";
import RenderContent from "../components/RenderContent";

import brainImage from "../images/insight-education_balanced-mind.png";
import Layout from "../components/Layout";
import SEO from "../components/seo";

import "./styles/contact.css";

const Contact = () => (
  <Layout selectedPage="contact">
    <SEO title="Contact Us" />
    <StaticQuery
      query={graphql`
        query {
          prismicContact {
            data {
              title {
                text
              }
              body {
                __typename
                ... on PrismicContactBodyParagraph {
                  id
                  slice_type
                  primary {
                    text {
                      text
                    }
                    style
                  }
                }
              }
            }
          }
        }
      `}
      render={results => {
        const { title = "", body = [] } = results.prismicContact.data;
        return (
          <section className="contact">
            <img className="contact-image" src={brainImage}  alt="" />
            <div className="contact-content">
              <h3>{title.text}</h3>
              <RenderContent content={body} />
              <p>
              Insight Education is based in Calgary, Alberta Canada. We offer our programs throughout Western Canada. 
              </p>
              <p>
              
                Email:{" "}
                <a href="mailto:hello@insighteducation.ca" target="__TOP">
                  hello@insighteducation.ca
                </a>
              </p>
            </div>
          </section>
        );
      }}
    />
  </Layout>
);

export default Contact;
